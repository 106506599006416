<template>
	<base-col col="12">
		<div class="ingredients-section">
			<base-font class="ingredients-heading" variant="medium-header" tag="h2">
				{{ value.heading }}
			</base-font>
			<div class="ingredients-list">
				<div class="ingredient" v-for="(ingredient, index) in ingredients" :key="index">
					<cms-image class="image" lazy :src="ingredient.image" :base="{width: 550}"/>
					<div class="text">
						<base-font class="name" variant="label" color="primary" uppercase>
							{{ ingredient.name }}
						</base-font>
						<base-font class="description" color="primary" :html="ingredient.description"/>
					</div>
				</div>
			</div>
		</div>
	</base-col>
</template>

<script>
export default {
	props: {
		value: Object,
		prefix: String
	},
	computed: {
		ingredients () {
			return this.value.ingredients
		}
	}
}
</script>

<style scoped lang="scss">
.ingredients-section {
	margin-top: 4rem;
	@include media-breakpoint-up(xl) {
		margin-top: 8rem;
	}

	:deep(.ingredients-heading) {
		text-align: center;
		display: block;
	}

	.ingredients-list {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		row-gap: 3rem;
		margin-top: 4rem;

		@include media-breakpoint-up(xl) {
			flex-direction: row;
			margin-top: 9rem;
			row-gap: 6rem;
			justify-content: space-between;
		}

		.ingredient {
			flex: 0 1 40%;
			display: flex;
			flex-direction: column;
			align-items: center;
			@include media-breakpoint-up(md) {
				flex-direction: row;
			}

			@include media-breakpoint-up(lg) {
				flex-direction: row;
			}

			@include media-breakpoint-up(xl) {
				flex-direction: row;
			}

			:deep(.image img) {
				width: 140px;
				image-rendering: -webkit-optimize-contrast;
			}

			.text {
				text-align: center;
				margin-top: 2rem;

				@include media-breakpoint-up(md) {
					margin-left: 5rem;
					text-align: left;
				}
				@include media-breakpoint-up(xxl) {
					min-width: 450px;
				}
			}

			:deep(.name) {
				font-size: 15px;
			}

			:deep(.description) {
				margin-top: 1rem;
				text-align: center;

				@include media-breakpoint-up(md) {
					text-align: left;
				}

				@include media-breakpoint-up(xl) {
					text-align: left;
					margin-top: 2rem;
				}

				p {
					letter-spacing: 0.48px;
					line-height: 30px;
				}
			}
		}
	}
}
</style>
